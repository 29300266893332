enum Feature {
    notificationUrlWithToken = 4096,
    useLogRocket = 4104,
    pulseSurveyCustomTextualQuestions = 6152,
    multipleRecipientsGoodVibes = 6156,
    publicGoodVibes = 6163,
    sliderQuestionCaptureAnswerWithSingleTap = 6166,
    surveyRecognitionActivityRebrand = 6178,
    customSurveyPermissionAlerts = 9232,
    useWorkleapBrand = 12026
}

export default Feature;
