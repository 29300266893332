import ImageSizes from "./ImageSizes";

enum WorkleapImageSizes {
    XXSmall = "16",
    XSmall = "24",
    Small = "32",
    XMedium = "48",
    Medium = "64",
    Large = "96",
    XLarge = "224",
    XXLarge = "240"
}

const mapOvImageSizeToWorkleap = (size?: ImageSizes): WorkleapImageSizes | null => {
    switch (size?.toString()) {
        case ImageSizes.XSmall:
            return WorkleapImageSizes.XSmall;
        case ImageSizes.Small:
            return WorkleapImageSizes.Small;
        case ImageSizes.XMedium:
            return WorkleapImageSizes.XMedium;
        case ImageSizes.Medium:
            return WorkleapImageSizes.Medium;
        case ImageSizes.XLarge:
            return WorkleapImageSizes.XLarge;
        case ImageSizes.XXLarge:
            return WorkleapImageSizes.XXLarge;
        default:
            return null;
    }
};

export { WorkleapImageSizes as default, mapOvImageSizeToWorkleap };